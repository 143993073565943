import { ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { filter, first, map, pairwise } from 'rxjs/operators';
import { TrackingService } from '../../core/tracking.service';
import { AnswerService } from '@core/answer.service';
import { Router } from '@angular/router';
var FilterGroupState = /** @class */ (function () {
    function FilterGroupState() {
        this.election = '';
        this.districtGroup = '';
        this.district = '';
        this.party = '';
        this.responderType = 'Party';
    }
    return FilterGroupState;
}());
export { FilterGroupState };
export function stateIsValid(state) {
    return state.election && state.district && state.responderType;
}
export function stateIsEqual(a, b) {
    return (a.election === b.election &&
        a.district === b.district &&
        a.districtGroup === b.districtGroup &&
        a.party === b.party &&
        a.responderType === b.responderType);
}
var FilterGroupComponent = /** @class */ (function () {
    function FilterGroupComponent(trackingService, _router, answerService, config) {
        this.trackingService = trackingService;
        this._router = _router;
        this.answerService = answerService;
        this.config = config;
        this.elections = [];
        this.stateChanged = new EventEmitter();
        this.fb = new FormBuilder();
        this.form = this.fb.group(new FilterGroupState());
        this.districtGroups = [];
        this.districts = [];
    }
    Object.defineProperty(FilterGroupComponent.prototype, "parties", {
        get: function () {
            if (!this.election) {
                return [];
            }
            return this.election.parties;
        },
        enumerable: true,
        configurable: true
    });
    FilterGroupComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Handles election, districtgroup select element behaviour
        this.formElectionSub = this.form.controls.election.valueChanges.subscribe(function (eleId) {
            // If currently no election is selected
            if (eleId === '') {
                _this.districts = [];
                _this.districtGroups = [];
                _this.form.controls.district.reset('', { emitEvent: false });
                _this.form.controls.districtGroup.reset('', { emitEvent: false });
                // otherwise prepare the necessary data
            }
            else {
                _this.districts = [];
                _this.election = _this.elections.find(function (e) { return e.id === eleId; });
                _this.districtGroups = _this.election.districtGroups;
                // reset respondertype to make sure behaviour is consistent with election that have hasPartyMatching
                _this.form.controls.responderType.setValue('Candidate');
                if (_this.election.districtGroups.length === 0 ||
                    _this.election.districtGroups.length === 1) {
                    _this.districts = _this.election.districts;
                }
                _this.resetDistrict();
                _this.resetDistrictGroup();
            }
        });
        // Handles election districtgroup, district select element behaviour
        this.formGroupSub = this.form.controls.districtGroup.valueChanges
            .pipe(filter(function (districtGroup) { return districtGroup; }))
            .subscribe(function (districtGroupId) {
            _this.districts = _this.election.districts.filter(function (d) { return d.groupId === districtGroupId; });
            _this.resetDistrict();
        });
        this.formSubFirst = this.form.valueChanges
            .pipe(first())
            .subscribe(function (_first) {
            _this.stateChanged.emit(_this.form.getRawValue());
        });
        this.formSub = this.form.valueChanges
            .pipe(
        /* using this.form.getRawValue() because we have disabled fields
          usaually disabled field would not be emitted */
        map(function (_v) { return _this.form.getRawValue(); }), pairwise())
            .subscribe(function (_a) {
            var prev = _a[0], current = _a[1];
            // checking previous state with current to prevent multiple emits with the same state
            if (!stateIsEqual(prev, current)) {
                _this.stateChanged.emit(current);
            }
        });
    };
    // elections and state(recommendation) are loaded asynchronous
    FilterGroupComponent.prototype.ngOnChanges = function () {
        var _this = this;
        if (this.state && this.elections) {
            // single election
            if (this.elections.length === 1 && this.districts.length === 0) {
                this.state.election = this.elections[0].id;
                this.form.controls.election.patchValue(this.state.election, { emitEvent: true });
            }
            // existing state / recommendation
            if (this.state.election && this.state.district) {
                this.election = this.elections.find(function (e) { return e.id === _this.state.election; });
                this.districtGroups = this.election.districtGroups;
                if (this.state.districtGroup) {
                    this.districts = this.election.districts.filter(function (d) { return d.groupId === _this.state.districtGroup; });
                }
                if (this.election.districtGroups.length === 0 ||
                    this.election.districtGroups.length === 1) {
                    this.districts = this.election.districts;
                }
                this.form.patchValue(this.state, { emitEvent: false });
                this.toggleDistrictGroup();
                this.toggleDistrict();
            }
        }
        this.calculatePercentageAnswered();
    };
    FilterGroupComponent.prototype.ngOnDestroy = function () {
        if (this.formSub) {
            this.formSub.unsubscribe();
        }
        if (this.formElectionSub) {
            this.formElectionSub.unsubscribe();
        }
        if (this.formGroupSub) {
            this.formGroupSub.unsubscribe();
        }
        if (this.formSubFirst) {
            this.formSubFirst.unsubscribe();
        }
    };
    FilterGroupComponent.prototype.onResponderTypeChange = function (responderType) {
        this.trackingService.trackEvent('Matching', 'selectResponderType', responderType);
        this.form.controls.responderType.setValue(responderType);
        this.resetParty();
    };
    FilterGroupComponent.prototype.trackSelectDistrict = function (districtId) {
        this.trackingService.trackEvent('Matching', 'selectDistrict', 'District' + districtId);
    };
    FilterGroupComponent.prototype.backToQuestionnaire = function () {
        this._router.navigate(['/matching']);
    };
    FilterGroupComponent.prototype.enoughAnswers = function () {
        var answersCount = this.answerService.getValidAnswersCount();
        return answersCount >= this.config.minQuestionsAnswered;
    };
    FilterGroupComponent.prototype.calculatePercentageAnswered = function () {
        var questionsCount = this.answerService.getQuestionCount();
        var answersCount = this.answerService.getValidAnswersCount();
        var value = ~~((answersCount / questionsCount) * 100);
        this.percentAnswered = value.toString() + '%';
    };
    FilterGroupComponent.prototype.resetDistrict = function () {
        if (this.districts && this.districts.length === 1) {
            this.form.controls.district.setValue(this.districts[0].id, { emitEvent: false });
        }
        else {
            this.form.controls.district.reset('', { emitEvent: false });
        }
        this.toggleDistrict();
    };
    FilterGroupComponent.prototype.toggleDistrict = function () {
        if (this.districts && this.districts.length === 1) {
            this.form.controls.district.disable({ emitEvent: false });
        }
        else {
            this.form.controls.district.enable({ emitEvent: false });
        }
    };
    FilterGroupComponent.prototype.resetDistrictGroup = function () {
        if (this.election && this.election.districtGroups.length === 1) {
            this.form.controls.districtGroup.setValue(this.election.districtGroups[0].id, {
                emitEvent: false
            });
        }
        else {
            this.form.controls.districtGroup.reset('', { emitEvent: false });
        }
        this.toggleDistrictGroup();
    };
    FilterGroupComponent.prototype.toggleDistrictGroup = function () {
        if (this.election && this.election.districtGroups.length === 1) {
            this.form.controls.districtGroup.disable({ emitEvent: false });
        }
        else {
            this.form.controls.districtGroup.enable({ emitEvent: false });
        }
    };
    FilterGroupComponent.prototype.resetParty = function () {
        this.form.controls.party.reset('', { emitEvent: true });
    };
    return FilterGroupComponent;
}());
export { FilterGroupComponent };
