/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./core/policy-layover/policy-layover.component.ngfactory";
import * as i2 from "./core/policy-layover/policy-layover.component";
import * as i3 from "@angular/common";
import * as i4 from "./core/tokens";
import * as i5 from "./core/cms.service";
import * as i6 from "./core/layout/layout.component.ngfactory";
import * as i7 from "./core/layout/layout.component";
import * as i8 from "./demo/demo.directive";
import * as i9 from "./demo/demo.service";
import * as i10 from "./demo/demo.module.configuiration";
import * as i11 from "@angular/router";
import * as i12 from "./app.component";
import * as i13 from "./core/tracking.service";
import * as i14 from "./core/research-id.service";
var styles_AppComponent = [""];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-policy-layover", [], null, [[null, "hasAccepted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hasAccepted" === en)) {
        var pd_0 = (_co.startTracking() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PolicyLayoverComponent_0, i1.RenderType_PolicyLayoverComponent)), i0.ɵdid(1, 4243456, null, 0, i2.PolicyLayoverComponent, [i3.Location, i0.PLATFORM_ID, i4.LocalStorage, i5.CmsService], null, { hasAccepted: "hasAccepted" })], null, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 16777216, null, null, 2, "svi-layout", [], null, [[null, "click"], [null, "touchstart"], [null, "touchend"], [null, "touchcancel"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onActivity($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onActivity($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i0.ɵnov(_v, 4).onActivity($event) !== false);
        ad = (pd_2 && ad);
    } if (("touchcancel" === en)) {
        var pd_3 = (i0.ɵnov(_v, 4).onActivity($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i6.View_LayoutComponent_0, i6.RenderType_LayoutComponent)), i0.ɵdid(3, 49152, null, 0, i7.LayoutComponent, [i5.CmsService], null, null), i0.ɵdid(4, 147456, null, 0, i8.DemoDirective, [i9.DemoService, i10.DEMO_MODULE_CONFIG, i0.PLATFORM_ID, i11.Router, i0.ViewContainerRef, i0.ComponentFactoryResolver], { sviDemo: [0, "sviDemo"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLayover; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.preventDemoStartUrls; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i12.AppComponent, [i13.TrackingService, i14.ResearchIdService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("svi-root", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
