import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { District, DistrictGroup, Election, Party } from '@smartvote/common'
import { Subscription } from 'rxjs'
import { filter, first, map, pairwise } from 'rxjs/operators'
import { TrackingService } from '../../core/tracking.service'
import { MATCHING_MODULE_CONFIG } from '../matching.module'
import { MatchingModuleConfiguration } from '../matching.module.configuration'
import { AnswerService } from '@core/answer.service'
import { Router } from '@angular/router'

export class FilterGroupState {
  election = ''
  districtGroup = ''
  district = ''
  party = ''
  responderType: 'Candidate' | 'Party' = 'Party'
}

export function stateIsValid(state: FilterGroupState) {
  return state.election && state.district && state.responderType
}

export function stateIsEqual(a: FilterGroupState, b: FilterGroupState) {
  return (
    a.election === b.election &&
    a.district === b.district &&
    a.districtGroup === b.districtGroup &&
    a.party === b.party &&
    a.responderType === b.responderType
  )
}

@Component({
  selector: 'svi-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss']
})
export class FilterGroupComponent implements OnDestroy, OnInit, OnChanges {
  @Input()
  state: FilterGroupState
  @Input()
  elections: Election[] = []
  @Input()
  disabled: boolean

  @Output() stateChanged = new EventEmitter<FilterGroupState>()

  @ViewChild('selectElection') private selectElection: ElementRef
  @ViewChild('selectDistrict') private selectDistrict: ElementRef

  get parties(): Party[] {
    if (!this.election) {
      return []
    }
    return this.election.parties
  }

  fb = new FormBuilder()
  form: FormGroup = this.fb.group(new FilterGroupState())
  formElectionSub: Subscription
  formGroupSub: Subscription
  formSub: Subscription
  formSubFirst: Subscription
  districtGroups: DistrictGroup[] = []
  districts: District[] = []
  election: Election
  percentAnswered: string

  constructor(
    private trackingService: TrackingService,
    private _router: Router,
    private answerService: AnswerService,
    @Inject(MATCHING_MODULE_CONFIG) readonly config: MatchingModuleConfiguration
  ) {}

  ngOnInit() {
    // Handles election, districtgroup select element behaviour
    this.formElectionSub = this.form.controls.election.valueChanges.subscribe((eleId) => {
      // If currently no election is selected
      if (eleId === '') {
        this.districts = []
        this.districtGroups = []
        this.form.controls.district.reset('', { emitEvent: false })
        this.form.controls.districtGroup.reset('', { emitEvent: false })
        // otherwise prepare the necessary data
      } else {
        this.districts = []
        this.election = this.elections.find((e) => e.id === eleId)
        this.districtGroups = this.election.districtGroups
        // reset respondertype to make sure behaviour is consistent with election that have hasPartyMatching
        this.form.controls.responderType.setValue('Candidate')
        if (
          this.election.districtGroups.length === 0 ||
          this.election.districtGroups.length === 1
        ) {
          this.districts = this.election.districts
        }
        this.resetDistrict()
        this.resetDistrictGroup()
      }
    })

    // Handles election districtgroup, district select element behaviour
    this.formGroupSub = this.form.controls.districtGroup.valueChanges
      .pipe(filter((districtGroup) => districtGroup))
      .subscribe((districtGroupId) => {
        this.districts = this.election.districts.filter((d) => d.groupId === districtGroupId)
        this.resetDistrict()
      })
    this.formSubFirst = this.form.valueChanges
      .pipe(first())
      .subscribe((_first: FilterGroupState) => {
        this.stateChanged.emit(this.form.getRawValue())
      })
    this.formSub = this.form.valueChanges
      .pipe(
        /* using this.form.getRawValue() because we have disabled fields
          usaually disabled field would not be emitted */
        map((_v) => this.form.getRawValue()),
        pairwise()
      )
      .subscribe(([prev, current]: [FilterGroupState, FilterGroupState]) => {
        // checking previous state with current to prevent multiple emits with the same state
        if (!stateIsEqual(prev, current)) {
          this.stateChanged.emit(current)
        }
      })
  }

  // elections and state(recommendation) are loaded asynchronous
  ngOnChanges() {
    if (this.state && this.elections) {
      // single election
      if (this.elections.length === 1 && this.districts.length === 0) {
        this.state.election = this.elections[0].id
        this.form.controls.election.patchValue(this.state.election, { emitEvent: true })
      }
      // existing state / recommendation
      if (this.state.election && this.state.district) {
        this.election = this.elections.find((e) => e.id === this.state.election)
        this.districtGroups = this.election.districtGroups
        if (this.state.districtGroup) {
          this.districts = this.election.districts.filter(
            (d) => d.groupId === this.state.districtGroup
          )
        }
        if (
          this.election.districtGroups.length === 0 ||
          this.election.districtGroups.length === 1
        ) {
          this.districts = this.election.districts
        }
        this.form.patchValue(this.state, { emitEvent: false })
        this.toggleDistrictGroup()
        this.toggleDistrict()
      }
    }

    this.calculatePercentageAnswered()
  }

  ngOnDestroy() {
    if (this.formSub) {
      this.formSub.unsubscribe()
    }
    if (this.formElectionSub) {
      this.formElectionSub.unsubscribe()
    }
    if (this.formGroupSub) {
      this.formGroupSub.unsubscribe()
    }
    if (this.formSubFirst) {
      this.formSubFirst.unsubscribe()
    }
  }

  onResponderTypeChange(responderType: string) {
    this.trackingService.trackEvent('Matching', 'selectResponderType', responderType)
    this.form.controls.responderType.setValue(responderType)
    this.resetParty()
  }

  trackSelectDistrict(districtId: string) {
    this.trackingService.trackEvent('Matching', 'selectDistrict', 'District' + districtId)
  }

  backToQuestionnaire() {
    this._router.navigate(['/matching'])
  }

  enoughAnswers() {
    const answersCount = this.answerService.getValidAnswersCount()
    return answersCount >= this.config.minQuestionsAnswered
  }

  calculatePercentageAnswered() {
    const questionsCount = this.answerService.getQuestionCount()
    const answersCount = this.answerService.getValidAnswersCount()
    const value = ~~((answersCount / questionsCount) * 100)
    this.percentAnswered = value.toString() + '%'
  }

  private resetDistrict() {
    if (this.districts && this.districts.length === 1) {
      this.form.controls.district.setValue(this.districts[0].id, { emitEvent: false })
    } else {
      this.form.controls.district.reset('', { emitEvent: false })
    }
    this.toggleDistrict()
  }

  private toggleDistrict() {
    if (this.districts && this.districts.length === 1) {
      this.form.controls.district.disable({ emitEvent: false })
    } else {
      this.form.controls.district.enable({ emitEvent: false })
    }
  }

  private resetDistrictGroup() {
    if (this.election && this.election.districtGroups.length === 1) {
      this.form.controls.districtGroup.setValue(this.election.districtGroups[0].id, {
        emitEvent: false
      })
    } else {
      this.form.controls.districtGroup.reset('', { emitEvent: false })
    }
    this.toggleDistrictGroup()
  }

  private toggleDistrictGroup() {
    if (this.election && this.election.districtGroups.length === 1) {
      this.form.controls.districtGroup.disable({ emitEvent: false })
    } else {
      this.form.controls.districtGroup.enable({ emitEvent: false })
    }
  }

  private resetParty() {
    this.form.controls.party.reset('', { emitEvent: true })
  }
}
