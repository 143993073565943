/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./navbar-menu/navbar-menu.component.ngfactory";
import * as i5 from "./navbar-menu/navbar-menu.component";
import * as i6 from "@angular/cdk/overlay";
import * as i7 from "./navbar.component";
import * as i8 from "../../core/tokens";
var styles_NavbarComponent = [i0.styles];
var RenderType_NavbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
function View_NavbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "nav-item nav-back"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-angle-double-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.backLabel; _ck(_v, 2, 0, currVal_0); }); }
function View_NavbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "spacer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "a", [["class", "nav-item"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(4, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Home"])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "a", [["class", "nav-item"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.goToMatching() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, [[4, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(10, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Matching"])), (_l()(), i1.ɵeld(14, 0, null, null, 5, "a", [["class", "nav-item"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 671744, [[6, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(16, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Datenbank"]))], function (_ck, _v) { var currVal_2 = "/home"; _ck(_v, 3, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 4, 0, currVal_3); var currVal_6 = "/matching"; _ck(_v, 9, 0, currVal_6); var currVal_7 = "active"; _ck(_v, 10, 0, currVal_7); var currVal_10 = "/profiles"; _ck(_v, 15, 0, currVal_10); var currVal_11 = "active"; _ck(_v, 16, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 9).target; var currVal_5 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_4, currVal_5); var currVal_8 = i1.ɵnov(_v, 15).target; var currVal_9 = i1.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_8, currVal_9); }); }
export function View_NavbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "d-none d-sm-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "svi-navbar"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "d-block d-sm-none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "svi-navbar-menu", [], null, null, null, i4.View_NavbarMenuComponent_0, i4.RenderType_NavbarMenuComponent)), i1.ɵdid(8, 49152, null, 0, i5.NavbarMenuComponent, [i6.Overlay, i1.Injector], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.backLabel; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.hideMenu; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_NavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i1.ɵdid(1, 49152, null, 0, i7.NavbarComponent, [i3.Location, i2.Router, i8.LocalStorage], null, null)], null, null); }
var NavbarComponentNgFactory = i1.ɵccf("svi-navbar", i7.NavbarComponent, View_NavbarComponent_Host_0, { backLabel: "backLabel", backRoute: "backRoute", backRouteParam: "backRouteParam", hideMenu: "hideMenu" }, {}, []);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };
