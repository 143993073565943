<div class="svi-matching-list-item">
  <svi-profile-photo class="profile-photo" [photoUrl]="photoUrl"></svi-profile-photo>
  <div class="main">
    <div class="info">
      <div class="title truncate">
        <ng-container *ngIf="rank && hasMatching">{{ rank }}. </ng-container>
        {{ title }}
      </div>
    </div>
    <div *ngIf="hasMatching" class="matching-value">
      <svi-matching-value-bar class="bar" [value]="matchingValue"></svi-matching-value-bar>
      <div *ngIf="!isPlaceholder" class="number">{{ matchingValue | number: '.1-1':'en' }}%</div>
    </div>
    <div
      *ngIf="!isPlaceholder && !hasMatching"
      class="profile-state"
      [ngClass]="{ withBackground: !isDirectory }"
    >
      <i *ngIf="!hasProfile" class="fa fa-times"></i>
      <i *ngIf="hasProfile" class="fa fa-check"></i>
      <div class="text" i18n="@@list.smartvoteProfile">smartvote-Profile</div>
    </div>
  </div>
  <i *ngIf="!isPlaceholder" class="fa fa-chevron-right list-item-arrow"></i>
</div>
