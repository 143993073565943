/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar-menu-overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./navbar-menu-overlay.component";
import * as i5 from "./navbar-menu-overlay-remote";
import * as i6 from "../../../../core/tokens";
var styles_NavbarMenuOverlayComponent = [i0.styles];
var RenderType_NavbarMenuOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarMenuOverlayComponent, data: {} });
export { RenderType_NavbarMenuOverlayComponent as RenderType_NavbarMenuOverlayComponent };
export function View_NavbarMenuOverlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "ul", [["class", "nav-list-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [["class", "nav-item-mobile"], ["routerLink", "/home"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Home"])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "a", [["class", "nav-item-mobile"], ["routerLink", "/matching"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.goToMatching() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, [[4, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(9, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Matching"])), (_l()(), i1.ɵeld(13, 0, null, null, 5, "a", [["class", "nav-item-mobile"], ["routerLink", "/profiles"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 671744, [[6, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(15, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Datenbank"]))], function (_ck, _v) { var currVal_2 = "/home"; _ck(_v, 2, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 3, 0, currVal_3); var currVal_6 = "/matching"; _ck(_v, 8, 0, currVal_6); var currVal_7 = "active"; _ck(_v, 9, 0, currVal_7); var currVal_10 = "/profiles"; _ck(_v, 14, 0, currVal_10); var currVal_11 = "active"; _ck(_v, 15, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 8).target; var currVal_5 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_8 = i1.ɵnov(_v, 14).target; var currVal_9 = i1.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_8, currVal_9); }); }
export function View_NavbarMenuOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-navbar-menu-overlay", [], null, null, null, View_NavbarMenuOverlayComponent_0, RenderType_NavbarMenuOverlayComponent)), i1.ɵdid(1, 49152, null, 0, i4.NavbarMenuOverlayComponent, [i5.NavbarMenuOverlayRemote, i2.Router, i6.LocalStorage], null, null)], null, null); }
var NavbarMenuOverlayComponentNgFactory = i1.ɵccf("svi-navbar-menu-overlay", i4.NavbarMenuOverlayComponent, View_NavbarMenuOverlayComponent_Host_0, {}, {}, []);
export { NavbarMenuOverlayComponentNgFactory as NavbarMenuOverlayComponentNgFactory };
