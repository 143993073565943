import { NgModule, InjectionToken, ModuleWithProviders } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { HomePage } from './home.page'
import { SharedModule } from '../shared/shared.module'
import { HomeModuleConfiguration } from './home.module.configuration'

const COMPONENTS = [HomePage]

export const HOME_MODULE_CONFIG = new InjectionToken<HomeModuleConfiguration>(
  'QuestionsAboutYouModuleConfiguration'
)

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class HomeModule {
  public static forRoot(config: HomeModuleConfiguration): ModuleWithProviders {
    return {
      ngModule: HomeModule,
      providers: [{ provide: HOME_MODULE_CONFIG, useValue: config }]
    }
  }
}
