import { Component, Inject, ViewChild, TemplateRef, AfterViewInit, LOCALE_ID } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Observable, combineLatest, of, BehaviorSubject } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'

import { Apollo } from 'apollo-angular'
import {
  Election,
  Recommendation,
  RecommendationOptions,
  Candidate,
  Party,
  Matching,
  Responder,
  ValueEntry,
  District
} from '@smartvote/common'
// TODO(JLO, 06.07.2020): make this import nicer (@smartvote/components) and rename it to SmartmapPosition?
import { Position } from '@smartvote/components/src/app/smartmap/smartmap.component'

import { AnswerService } from '../core/answer.service'
import { VoterIdService } from '../core/voter-id.service'
import { ResearchIdService } from '@core/research-id.service'
import { LocalStorage } from '../core/tokens'
import { CmsService, Document } from '../core/cms.service'

import { GetElectionsQuery, GetRecommendationQuery } from '../../__generated__/types'
import { FilterGroupState, stateIsEqual, stateIsValid } from './filter-group/filter-group.component'
import { USER_SURVEY_KEY } from '../questions-about-you/questions-about-you.page'
import { TrackingService } from '../core/tracking.service'
import { MATCHING_MODULE_CONFIG } from './matching.module'
import { MatchingModuleConfiguration } from './matching.module.configuration'
import { IframeSettings, IFRAME_SETTINGS } from '@core/iframe'
import {
  QUESTIONNAIRE_START,
  QUESTIONNAIRE_END,
  RECOMMENDATION_CREATED_AT
} from 'app/questionnaire/questionnaire.page'

const {
  CreateRecommendation,
  GetRecommendation,
  GetElections
} = require('graphql-tag/loader!./matching.page.graphql')

@Component({
  selector: 'svi-matching-page',
  templateUrl: 'matching.page.html',
  styleUrls: ['matching.page.scss']
})
export class MatchingPage implements AfterViewInit {
  @ViewChild('translations') translationTemplate: TemplateRef<any>
  translations: any
  tabIndex = 0
  showVoterPosition = true
  elections: Observable<Election[]>
  recommendation: Observable<Recommendation>
  filterGroupStateChanges: BehaviorSubject<FilterGroupState> = new BehaviorSubject(
    new FilterGroupState()
  )
  filterGroupStateFromRecommendation: FilterGroupState = {
    district: '',
    districtGroup: '',
    election: '',
    responderType: 'Candidate',
    party: ''
  }

  resultSummary = {
    district: '',
    nofSeats: '',
    nofCandidates: ''
    // nofParticipatingCandidates: '' // TODO: Figure out how to get this number
  }
  nofSeats: number
  loadingRecommendation = true
  loadingPrismic = false
  // Current states
  private _elections: Election[]
  private _recommendation: Recommendation
  private _filterGroupState: FilterGroupState = new FilterGroupState()
  matchingPageDoc: Observable<Document>

  constructor(
    private apollo: Apollo,
    private router: Router,
    private route: ActivatedRoute,
    private answerService: AnswerService,
    private voterIdService: VoterIdService,
    private researchIdService: ResearchIdService,
    private trackingService: TrackingService,
    @Inject(LocalStorage) private _localStorage: Storage,
    @Inject(LOCALE_ID) public localeId: string,
    @Inject(MATCHING_MODULE_CONFIG) public readonly config: MatchingModuleConfiguration,
    @Inject(IFRAME_SETTINGS) private iframeSettings: IframeSettings,
    cms: CmsService
  ) {
    this.elections = this._getElections() as any // TODO: Fix types
    this.recommendation = combineLatest([
      this.route.queryParams,
      this.filterGroupStateChanges,
      this.elections
    ]).pipe(
      switchMap(([params, filterGroupState, elections]) => {
        this._elections = elections
        this.tabIndex = parseInt(params['tab'], 10) || 0
        const recommendationId = params['rid']
        this.loadingRecommendation = true
        if (this._doCreateRecommendation(filterGroupState)) {
          return this.createRecommendation(filterGroupState)
        } else if (this._doGetRecommendation(recommendationId)) {
          return this._getRecommendation(recommendationId, 0, -1)
        } else if (stateIsValid(filterGroupState)) {
          return of(this._recommendation)
        } else {
          this._updateQueryParams({ rid: null })
          return of(null)
        }
      }),
      tap((recommendation: any) => {
        // TODO(JLO, 03.09.18): redirect to share view if it's not me
        this.loadingRecommendation = false
        if (!recommendation) {
          return
        }
        recommendation.positions = getSmartmapPositions(recommendation)
        recommendation.smartmapLegendItems = getSmartmapLegendItems(recommendation)
        recommendation.matchings = recommendation.matchings.map((matching: Matching) => ({
          ...matching,
          ...this._getListItemLabels(matching)
        }))
        if (!this._recommendation || this._recommendation.id !== recommendation.id) {
          // Keep last state before emiting to avoid infite loop
          const districts = this._elections.filter(
            (e) => e.id === recommendation.options.electionId
          )[0].districts
          const districtGroupId = districts
            .filter((d) => d.id === recommendation.options.districtId)
            .map((d) => d.groupId)[0]
          // Input districtGroupId manually, since it is not in recommendation.options
          this._filterGroupState = this._getFilterGroupState({
            ...recommendation.options,
            districtGroupId
          })

          this._recommendation = recommendation
          this._updateQueryParams({ rid: recommendation.id })
          this._updateResultSummary(this._elections, this._filterGroupState, recommendation)
          this._updateNofSeats(this._elections, this._filterGroupState)
          this.filterGroupStateFromRecommendation = { ...this._filterGroupState }
        }
        this._localStorage.setItem('recommendationId', recommendation.id)
      })
    ) as any
    this.loadingPrismic = true
    this.matchingPageDoc = cms.documents('matching_page').pipe(
      map((docs) => {
        this.loadingPrismic = false
        return docs[Object.keys(docs)[0]]
      })
    )
  }

  ngAfterViewInit() {
    this.translations = this.translationTemplate
      .createEmbeddedView({})
      .rootNodes.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.textContent }), {})
  }

  trackSharingTrigger() {
    this.trackingService.trackEvent('Matching', 'openSharingDialog')
  }

  onFilterGroupChanged(state: FilterGroupState) {
    this.filterGroupStateChanges.next(state)
  }

  onTabChanged(index: number) {
    this._updateQueryParams({ tab: index })
  }

  private _updateQueryParams(params) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      replaceUrl: true,
      queryParamsHandling: 'merge'
    })
  }

  private _updateResultSummary(
    elections: Election[],
    filterGroupState: FilterGroupState,
    recommendation: Recommendation
  ) {
    if (!(elections && filterGroupState && recommendation)) {
      return
    }
    const election = elections.find((e: Election) => e.id === filterGroupState.election)
    if (!election) {
      return
    }
    const district = election.districts.find((d: District) => d.id === filterGroupState.district)
    this.resultSummary = {
      district: district.name,
      nofSeats: `${district.seats}`,
      nofCandidates: `${recommendation.matchings.length}`
    }
  }

  private _updateNofSeats(elections: Election[], filterGroupState: FilterGroupState) {
    if (!elections) {
      return
    }
    const election = elections.find((e: Election) => e.id === filterGroupState.election)
    if (!election) {
      return
    }
    const district = election.districts.find((d: District) => d.id === filterGroupState.district)
    this.nofSeats = district.seats
  }

  private _getListItemLabels(matching: Matching) {
    let title = ''
    let description = ''
    if (matching.responderType === 'Candidate') {
      const candidate = matching.responder as Candidate
      if (candidate) {
        title = `${candidate.firstname} ${candidate.lastname}`
        description = candidate.party ? candidate.party.abbreviation : ''
      } else {
        title = this.getTranslatedMessage('no-information')
      }
    } else if (matching.responderType === 'Party') {
      const party = matching.responder as Party
      if (party) {
        title = party.name
      } else {
        title = this.getTranslatedMessage('no-information')
      }
    }
    return { title, description, matchingValue: matching.matchValue }
  }

  private _getFilterGroupState(options: RecommendationOptions & { partyId?: string }) {
    return {
      election: options.electionId,
      district: options.districtId,
      districtGroup: options.districtGroupId,
      party: options.partyId,
      responderType: options.responderType
    }
  }

  private _getElections() {
    return this.apollo
      .query<GetElectionsQuery>({
        query: GetElections
      })
      .pipe(map(({ data }) => data.elections))
  }

  private _getRecommendation(recommendationId: string, offset: number, limit: number): any {
    return this.apollo
      .query<GetRecommendationQuery>({
        query: GetRecommendation,
        variables: {
          recommendationId,
          offset,
          limit
        }
      })
      .pipe(map(({ data }) => ({ ...data.recommendation })))
  }

  private _doCreateRecommendation(filterGroupState: FilterGroupState): boolean {
    if (stateIsEqual(filterGroupState, this._filterGroupState) || !stateIsValid(filterGroupState)) {
      return false
    } else {
      return true
    }
  }

  private _doGetRecommendation(rid: string) {
    return rid && !this._recommendation
  }

  private createRecommendation(filterGroupState: FilterGroupState): Observable<Recommendation> {
    this._localStorage.setItem(RECOMMENDATION_CREATED_AT, new Date().getTime().toString())
    const answers = this.answerService.getAnswers()
    const surveyData = this._localStorage.getItem(USER_SURVEY_KEY) || ''
    const start = this._localStorage.getItem(QUESTIONNAIRE_START) || ''
    const end = this._localStorage.getItem(QUESTIONNAIRE_END) || ''
    const options: RecommendationOptions & { partyId?: string } = {
      responderType: filterGroupState.responderType,
      electionId: filterGroupState.election,
      districtId: filterGroupState.district,
      partyId: filterGroupState.party,
      voterId: this.voterIdService.getVoterId(),
      researchId: this.researchIdService.getResearchId(),
      answers,
      surveyData,
      start,
      end
    }
    if (this.iframeSettings.enabled) {
      options.inlineFrameId = this.iframeSettings.id
    }
    return this.apollo
      .mutate<any>({
        mutation: CreateRecommendation,
        variables: {
          options
        }
      })
      .pipe(map(({ data }) => ({ ...data.createRecommendation })))
  }

  goToUserSurvey() {
    this.router.navigate(['matching', 'questions-about-you'], { queryParams: { fwd: true } })
  }

  onMatchingSelected(matching: Matching) {
    if (matching.responder) {
      if (matching.responderType === 'Candidate') {
        this.router.navigate(
          [
            'matching',
            'profile',
            'candidate',
            matching.responder.id,
            (matching.responder as Candidate).district.electionId
          ],
          { queryParamsHandling: 'preserve' }
        )
      } else if (matching.responderType === 'Party') {
        this.router.navigate(['matching', 'profile', 'party', matching.responder.id], {
          queryParamsHandling: 'preserve'
        })
      }
    }
  }

  onSmartmapItemSelect(itemId: string) {
    const selectedMatching = this._recommendation.matchings.filter(
      (matching: Matching) => matching.responderId === itemId
    )
    if (selectedMatching.length === 1) {
      this.onMatchingSelected(selectedMatching[0])
    }
  }

  private getTranslatedMessage(msg: string): string {
    return this.translations[msg]
  }
}

export function getValue(responder: Responder, key: string) {
  if (!responder) {
    return null
  }
  const result = responder.values.find((entry: ValueEntry) => entry.key === key)
  return result ? result.value : null
}

export function getSmartmapLegendItems(
  recommendation: Recommendation
): { label: string; color: string }[] {
  const partyColors: { [partyName: string]: string } = {}
  recommendation.matchings
    .filter((matching: Matching) => !!matching.responder && matching.responder.nofAnswers > 0)
    .forEach((matching: Matching) => {
      if (matching.responderType === 'Candidate') {
        const candidate = matching.responder as Candidate
        if (candidate.party) {
          const label = candidate.party.abbreviation || candidate.party.name
          partyColors[label] = '#' + getValue(candidate.party, 'color')
        }
      } else if (matching.responderType === 'Party') {
        const party = matching.responder as Party
        const label = party.abbreviation || party.name
        partyColors[label] = '#' + getValue(party, 'color')
      }
    })
  return Object.keys(partyColors).map((k: string) => {
    return { label: k, color: partyColors[k] }
  })
}

export function getSmartmapPositions(recommendation: Recommendation): Position[] {
  return recommendation.matchings
    .filter((matching: Matching) => !!matching.responder && matching.responder.nofAnswers > 0)
    .map((matching: Matching) => {
      if (matching.responderType === 'Candidate') {
        const candidate = matching.responder as Candidate
        const candidateLabel = candidate.party
          ? `${candidate.firstname} ${candidate.lastname} (${candidate.party.name})`
          : `${candidate.firstname} ${candidate.lastname}`
        return {
          id: candidate.id,
          color: '#' + getValue(candidate.party, 'color'),
          label: candidateLabel,
          ...candidate.smartmapPosition
        }
      } else if (matching.responderType === 'Party') {
        const party = matching.responder as Party
        return {
          id: party.id,
          color: '#' + getValue(party, 'color'),
          label: party.name,
          ...party.smartmapPosition
        }
      }
    })
}
