/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/navbar/navbar.component.ngfactory";
import * as i3 from "../shared/navbar/navbar.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "../core/tokens";
import * as i7 from "../shared/page-title/page-title.component.ngfactory";
import * as i8 from "../shared/page-title/page-title.component";
import * as i9 from "./home.page";
import * as i10 from "apollo-angular";
import * as i11 from "../core/cms.service";
import * as i12 from "./home.module";
var styles_HomePage = [i0.styles];
var RenderType_HomePage = i1.ɵcrt({ encapsulation: 0, styles: styles_HomePage, data: {} });
export { RenderType_HomePage as RenderType_HomePage };
function View_HomePage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "sticker"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "text-small"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bereits"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "voter-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "text-small"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Matchings"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.recommendationCountLabel; _ck(_v, 5, 0, currVal_0); }); }
function View_HomePage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "className", 0], [8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.className; var currVal_1 = _v.context.$implicit.html; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_HomePage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(1, 49152, null, 0, i3.NavbarComponent, [i4.Location, i5.Router, i6.LocalStorage], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "svi-page-title", [["class", "d-none d-sm-block"]], null, null, null, i7.View_PageTitleComponent_0, i7.RenderType_PageTitleComponent)), i1.ɵdid(3, 49152, null, 0, i8.PageTitleComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["Willkommen bei smartvote \u00D6sterreich"])), (_l()(), i1.ɵeld(5, 0, null, null, 16, "div", [["class", "home-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "section", [["class", "head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "content hero"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nationalratswahl 2024"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Finden Sie Parteien, die Ihre Meinung teilen!"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["class", "button button-start"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startQuestionnaire() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Jetzt starten"])), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "fas fa-angle-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomePage_1)), i1.ɵdid(17, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 3, "div", [["class", "cms-tiles-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_HomePage_2)), i1.ɵdid(20, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.recommendationCount >= _co.config.minRecommendationCount); _ck(_v, 17, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform(_co.docs)); _ck(_v, 20, 0, currVal_1); }, null); }
export function View_HomePage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-home", [], null, null, null, View_HomePage_0, RenderType_HomePage)), i1.ɵdid(1, 180224, null, 0, i9.HomePage, [i5.Router, i10.Apollo, i11.CmsService, i12.HOME_MODULE_CONFIG], null, null)], null, null); }
var HomePageNgFactory = i1.ɵccf("svi-home", i9.HomePage, View_HomePage_Host_0, {}, {}, []);
export { HomePageNgFactory as HomePageNgFactory };
