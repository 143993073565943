import { OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnswerService } from '@core/answer.service';
import { Apollo } from 'apollo-angular';
import { map, switchMap, tap } from 'rxjs/operators';
export var QUESTIONNAIRE_START = 'start';
export var QUESTIONNAIRE_END = 'end';
export var RECOMMENDATION_CREATED_AT = 'recommmendation_created_at';
var query = require('graphql-tag/loader!./questionnaire.query.graphql');
var QuestionnairePage = /** @class */ (function () {
    function QuestionnairePage(config, localStorage, route, router, apollo, answerService) {
        this.config = config;
        this.localStorage = localStorage;
        this.route = route;
        this.router = router;
        this.apollo = apollo;
        this.answerService = answerService;
    }
    QuestionnairePage.prototype.ngOnInit = function () {
        var _this = this;
        this.questions = this.route.queryParamMap.pipe(tap(function (params) {
            _this.questionIndex = parseInt(params.get('idx'), 10) || 0;
        }), switchMap(function () { return _this._getQuery(); }), map(function (_a) {
            var data = _a.data, loading = _a.loading;
            var questions = data.questionnaire.questions;
            _this.answerService.setQuestionCount(questions.length);
            _this.userAnswers = _this.answerService.getUserAnswersAsArray(questions);
            _this.hasAnswers = _this.userAnswers.some(function (a) { return a.value >= 0; });
            _this.progress = _this.questionIndex + 1 + "/" + questions.length;
            return questions;
        }));
    };
    QuestionnairePage.prototype.ngAfterViewInit = function () {
        this._setStart();
    };
    QuestionnairePage.prototype.submitAnswer = function (answer) {
        var _this = this;
        this.hasAnswers = this.userAnswers.some(function (a) { return a.value >= 0; });
        this.answerService.saveAnswer(answer);
        if (this.config.oneClickSubmit) {
            if (this.questionIndex !== this.answerService.getQuestionCount() - 1) {
                setTimeout(function () { return _this.jumpToQuestion(_this.questionIndex + 1); }, 250);
            }
            else {
                setTimeout(function () { return _this.showResults(); }, 250);
            }
        }
    };
    QuestionnairePage.prototype.navigate = function (direction) {
        var newQuestionIndex = this.questionIndex + parseInt(direction, 10);
        this.jumpToQuestion(newQuestionIndex);
    };
    QuestionnairePage.prototype.jumpToQuestion = function (newQuestionIndex) {
        if (this.userAnswers[this.questionIndex].value < -9) {
            this.answerService.saveAnswer(Object.assign(this.userAnswers[this.questionIndex], { value: -9 }));
        }
        this.router.navigate(['matching', 'questionnaire'], {
            queryParams: { idx: newQuestionIndex },
            replaceUrl: true
        });
    };
    QuestionnairePage.prototype.showResults = function () {
        this._setEnd();
        this.router.navigate(['matching', 'questions-about-you'], { queryParams: { fwd: true } });
    };
    QuestionnairePage.prototype._getQuery = function () {
        return this.apollo.query({
            query: query
        });
    };
    QuestionnairePage.prototype._setStart = function () {
        var created_rec = this.localStorage.getItem(RECOMMENDATION_CREATED_AT);
        var questionnaire_start = this.localStorage.getItem(QUESTIONNAIRE_START);
        if (!questionnaire_start || Number(created_rec) > Date.parse(questionnaire_start)) {
            this.localStorage.setItem(RECOMMENDATION_CREATED_AT, '0');
            this.localStorage.setItem(QUESTIONNAIRE_START, new Date().toISOString());
        }
    };
    QuestionnairePage.prototype._setEnd = function () {
        var created_rec = this.localStorage.getItem(RECOMMENDATION_CREATED_AT);
        if (!this.localStorage.getItem(QUESTIONNAIRE_END) || !created_rec || created_rec === '0') {
            this.localStorage.setItem(QUESTIONNAIRE_END, new Date().toISOString());
        }
    };
    return QuestionnairePage;
}());
export { QuestionnairePage };
