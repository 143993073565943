<div class="svi-matching-filter-group">
  <form [formGroup]="form">
    <ng-container *ngIf="!enoughAnswers()"
      ><div class="form-error">
        <span class="form-error-title" i18n="@@filter.formErrorTitle">Attention:</span>
        <span i18n="@@filter.formErrorText"
          >You have only answered {{ percentAnswered }} of the questions. For better results answer
          more questions.</span
        ><span class="form-error-link" i18n="@@filter.formErrorLink" (click)="backToQuestionnaire()"
          >Return to questionnaire <i class="fa fa-angle-right"></i
        ></span></div
    ></ng-container>
    <div
      *ngIf="elections && elections.length > 1"
      class="form-title"
      i18n="@@filter.selectElection"
    >
      Select Election
    </div>
    <select
      [sviFocus]="this.elections && this.state.election === ''"
      *ngIf="elections && elections.length > 1"
      class="form-control"
      formControlName="election"
    >
      <option value="">-</option>
      <option *ngFor="let election of elections" [value]="election.id">{{ election.name }}</option>
    </select>

    <ng-container *ngIf="districtGroups && districtGroups.length > 0">
      <div class="form-title" i18n="@@filter.selectDistrictGroup">Select District Group</div>
      <select class="form-control" formControlName="districtGroup">
        <option value="">-</option>
        <option *ngFor="let districtGroup of districtGroups" [value]="districtGroup.id">
          {{ districtGroup.name }}
        </option>
      </select>
    </ng-container>
    <ng-container *ngIf="districts[0]">
      <div class="form-title" i18n="@@filter.selectDistrict">Select District</div>
      <select
        [sviFocus]="this.elections && this.elections.length === 1 && this.state.district === ''"
        #selectDistrict
        class="form-control select-district"
        formControlName="district"
        (change)="trackSelectDistrict($event.target.value)"
      >
        <option value="">-</option>
        <option *ngFor="let district of districts" [value]="district.id">
          {{ district.name }}
        </option>
      </select>
    </ng-container>

    <span
      *ngIf="config.electorateHintUrl && districts[0]"
      i18n="@@matching.electorateHint"
      class="electorate-hint"
    >
      Still can’t find your electorate? Go to the
      <a class="electorate-hint electorate-link" [href]="config.electorateHintUrl" target="_blank">
        Destination
      </a>
    </span>
  </form>
</div>
