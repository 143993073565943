import { ActivatedRoute } from '@angular/router'
import { Injectable, Inject } from '@angular/core'
import { LocalStorage } from './tokens'

@Injectable()
export class ResearchIdService {
  constructor(@Inject(LocalStorage) private localStorage: Storage, private route: ActivatedRoute) {
    this.route.queryParamMap.subscribe((params) => {
      const paramResearchId = params.get('researchId')
      if (paramResearchId) {
        this.localStorage.setItem('smv-researchId', paramResearchId)
      }
    })
  }

  getResearchId() {
    return this.localStorage.getItem('smv-researchId')
  }
}
