import { Component, OnInit } from '@angular/core'
import { ResearchIdService } from './core/research-id.service'
import { TrackingService } from './core/tracking.service'
import { environment } from 'environments/environment'

@Component({
  selector: 'svi-root',
  template: `
    <svi-policy-layover *ngIf="showLayover" (hasAccepted)="startTracking()"></svi-policy-layover>
    <svi-layout [sviDemo]="preventDemoStartUrls"> </svi-layout>
  `,
  styles: ['']
})
export class AppComponent implements OnInit {
  showLayover = environment.showPrivacyLayover
  preventDemoStartUrls = ['/home', '/']
  constructor(
    private trackingService: TrackingService,
    private researchIdService: ResearchIdService
  ) {}

  ngOnInit() {
    if (!this.showLayover) {
      this.startTracking()
    }
  }

  startTracking() {
    this.trackingService.init()
    this.trackingService.trackAllPageViews()
  }
}
